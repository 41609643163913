import React from "react";
import PropTypes from "prop-types";

const LicenseViewer = ({ handleCancel, license }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-header">
          <p className="large-text white-text center-text flex-grow">
            View License
          </p>
          <div onClick={handleCancel} id="hide-view-license">
            <svg className="icon-small icon-small-back">
              <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
            </svg>
          </div>
        </div>
        <div className="popup-body popup-body-evenly">
          {license ? (
            <img
              className="license-full"
              alt="customer_license"
              src={`${license}`}
            />
          ) : (
            <div className="dark-blue-green-text large-text">
              No License Found!
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LicenseViewer.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  license: PropTypes.string,
};

export default LicenseViewer;
