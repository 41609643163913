import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import parsePhoneNumber from "libphonenumber-js";
import format from "date-fns/format";
import UserContext from "../context/users/userContext";
import DealershipContext from "../context/dealership/dealershipContext";
import {
  getCurrentVehicle,
  getCyclesOfCustomer,
  mergeExistingCustomers,
  setSearchedCustomer,
} from "../functions/customerFunctions";

const CustomerSearch = () => {
  const dealershipContext = useContext(DealershipContext);
  const userContext = useContext(UserContext);

  const mainBodyRef = useRef();
  const possibleDuplicateSection = useRef();
  const mergeButton = useRef();
  // const checkSearchResultsRef = useRef();
  const allCustomersRef = useRef();
  const activeCycleRef = useRef();
  const hotCycleRef = useRef();

  const history = useHistory();

  const [searchResultsState, setSearchResultsState] = useState([]);
  const [customersToMerge, setCustomersToMerge] = useState([]);
  const [searchComplete, setSearchComplete] = useState(false);
  const [activeCycleSelected, setActiveCycleSelected] = useState(false);
  const [hotCycleSelected, setHotCycleSelected] = useState(false);
  const [searchType, setSearchType] = useState("all-fields");
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCustomerSearch();
    // eslint-disable-next-line
  }, []);

  const loadCustomerSearch = async () => {
    if (!searchComplete && userContext && mainBodyRef.current) {
      const fullObjects = await userContext.getLastTwoHundredFullObjects();

      setSearchResultsState(fullObjects);
      // is this next line necessary?
      setSearchComplete(true);
    }

    if (mainBodyRef.current) {
      if (!activeCycleSelected && !hotCycleSelected) {
        allCustomersRef.current.setAttribute("checked", true);
      } else if (activeCycleSelected && !hotCycleSelected) {
        activeCycleRef.current.setAttribute("checked", true);
      } else {
        hotCycleRef.current.setAttribute("checked", true);
      }
    }

    //
  };

  const setMergeButtonDisplay = (e) => {
    if (e.target.classList.contains("checkbox-clickable")) {
      const checkBoxes = Array.from(
        document.getElementsByClassName("checkbox-clickable")
      );
      const newArray = customersToMerge;
      let checkedBoxes = 0;
      let customerId;
      for (let i = 0; i < checkBoxes.length; i++) {
        customerId = checkBoxes[i].getAttribute("id");
        if (checkBoxes[i].checked) {
          if (!newArray.includes(customerId)) {
            newArray.push(customerId);
          } else {
            // console.log(`newArray already has ${customerId} in it`);
          }

          checkedBoxes++;
        } else {
          // iteration for an unchecked checkbox
          // here we remove unchecked customers from our customers to merge
          if (newArray.includes(customerId)) {
            newArray.splice(newArray.indexOf(customerId), 1);
          }
        }
      }
      // this is after every checkbox has been iterated through

      if (checkedBoxes >= 2) {
        setCustomersToMerge(newArray);
        mergeButton.current.classList.remove("button-normal-disabled");
      } else {
        mergeButton.current.classList.add("button-normal-disabled");
      }
    }
  };

  const handleActiveRadio = (e) => {
    if (mainBodyRef.current) {
      if (e.target.id === "search-type-all") {
        // all customer search
        allCustomersRef.current.setAttribute("checked", true);
        setActiveCycleSelected(false);
        setHotCycleSelected(false);
      } else if (e.target.id === "search-type-active") {
        // active customer search
        activeCycleRef.current.setAttribute("checked", true);
        setActiveCycleSelected(true);
        setHotCycleSelected(false);
      } else if (e.target.id === "search-type-hot") {
        hotCycleRef.current.setAttribute("checked", true);
        setActiveCycleSelected(false);
        setHotCycleSelected(true);
        // console.log(
        //   "Must select All Customers or Active Cycle - no selection detected..."
        // );
      }
    }
  };

  const handleSearchTypeChange = (e) => {
    //
    if (mainBodyRef.current) {
      switch (e.target.value) {
        case "all-fields":
          setSearchType("all-fields");
          break;
        //
        case "first":
          setSearchType("first");
          break;
        //
        case "last":
          setSearchType("last");
          break;
        //
        case "phone":
          setSearchType("phone");
          break;
        //
        case "email":
          setSearchType("email");
          break;
        //
        case "zip":
          setSearchType("zip");
          break;
        // - this still needs to be implemented...
        case "stock":
          setSearchType("stock");
          break;
        //
        default:
      }
    }
    //
  };

  const handleSearchTextChange = (e) => {
    if (mainBodyRef.current) {
      setSearchText(e.target.value);
    }
  };

  const mergeCustomerHandler = async () => {
    // now we should have the correct array of _ids in customersToMerge
    // let's start by unchecking all currently checked boxes...
    setLoading(true);

    const checkBoxes = Array.from(
      document.getElementsByClassName("checkbox-clickable")
    );
    checkBoxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.checked = false;
      }
    });

    // first we need to send our customersToMerge to the server for actual merging of data
    // console.log("ABOUT TO MERGE CUSTOMERS");
    const mergedCustomerData = await mergeExistingCustomers(customersToMerge);

    const vehicleData = await getCurrentVehicle(mergedCustomerData.cycles);

    //
    // next we need to set the matches without anything in the mergeArray
    // console.log("ABOUT TO SET POSSIBLE DUPLICATES");
    dealershipContext.purgeMerged(customersToMerge);

    if (mergedCustomerData) {
      setSearchedCustomer(
        JSON.stringify(mergedCustomerData),
        mergedCustomerData.searchedCustomerType,
        vehicleData
      );

      const correctArray = await dealershipContext.findMatches(
        mergedCustomerData
      );

      setLoading(false);

      safeStateSetter(() => {
        // setSearchedCustomerState(mergedCustomerData);
        setSearchResultsState(correctArray);
        // setDataMerged(true);
      });
    }
    // done!
  };

  const search = async () => {
    try {
      let results;
      switch (searchType) {
        case "all-fields":
          results = await dealershipContext.search({
            allFields: searchText,
            searchType,
            activeCycleSelected,
            withinGroup: [],
            hotCycleSelected,
          });
          break;
        case "first":
          results = await dealershipContext.search({
            firstName: searchText,
            searchType,
            activeCycleSelected,
            withinGroup: [],
            hotCycleSelected,
          });
          break;
        case "last":
          results = await dealershipContext.search({
            lastName: searchText,
            searchType,
            activeCycleSelected,
            withinGroup: [],
            hotCycleSelected,
          });
          break;
        case "phone":
          results = await dealershipContext.search({
            phoneNumber: searchText,
            searchType,
            activeCycleSelected,
            withinGroup: [],
            hotCycleSelected,
          });
          break;
        case "email":
          results = await dealershipContext.search({
            emailAddress: searchText,
            searchType,
            activeCycleSelected,
            withinGroup: [],
            hotCycleSelected,
          });
          break;
        case "zip":
          results = await dealershipContext.search({
            zipCode: searchText,
            searchType,
            activeCycleSelected,
            withinGroup: [],
            hotCycleSelected,
          });
          break;
        // case "stock":
        //   dealershipContext.search({
        //     ???: searchText,
        //     searchType,
        //   });
        // break;
        default:
      }
      if (results) {
        setSearchResultsState(results);
      }
    } catch (error) {
      console.log("CustomerSearch.search: ", error.message);
    }
  };

  const handleResultClick = async (e) => {
    // here we need to check whether this customer has an active cycle and forward appropriately
    let currentTarget = e.target;

    while (currentTarget.parentElement.getAttribute("id") !== "parent") {
      currentTarget = currentTarget.parentElement;
    }

    const currentCustomer = JSON.parse(currentTarget.id);

    const { cycles } = currentCustomer;

    if (cycles.length > 0) {
      // we can't have getSubdocumentsOfCustomer here because it's about to get called again in either Cycle.js or Customer.js

      // we've got to get what we want on the server side
      // what we want is the full cycles
      const objWithCycleArrayOfCustomer = await getCyclesOfCustomer(
        currentCustomer._id
      );

      const fullCycles = objWithCycleArrayOfCustomer.cycles;
      let activeCycleFound = false;
      let activeCycleObject;
      // here we need fullCycles to be objects, not ObjectIds...
      fullCycles.forEach((cycle) => {
        if (cycle.active) {
          activeCycleFound = true;
          activeCycleObject = cycle;
        } else {
          // console.log("cycle is not active");
        }
      });
      if (activeCycleFound) {
        localStorage.setItem(
          "localCurrentCustomer",
          JSON.stringify(currentCustomer)
        );
        localStorage.setItem(
          "localCurrentCycle",
          JSON.stringify(activeCycleObject)
        );
        history.push("/cycle");
      } else {
        // will need to pass data next time we are here

        localStorage.setItem("localCurrentCustomer", currentCustomer._id);
        localStorage.setItem("localCurrentCycle", null);

        history.push("/customer");
      }
    } else {
      // will need to pass data next time we are here

      localStorage.setItem("localCurrentCustomer", currentCustomer._id);
      localStorage.setItem("localCurrentCycle", null);
      history.push("/customer");
    }
  };

  const safeStateSetter = (fn) => {
    if (mainBodyRef.current) {
      fn();
    }
  };

  //
  //
  //
  //
  //
  //

  return (
    <Fragment>
      {loading ? (
        <div
          className="full-page-center full-page-center-with-navbar"
          ref={mainBodyRef}
        >
          <div className="lds-dual-ring"></div>
        </div>
      ) : (
        <div className="popup" id="popup-my-customers" ref={mainBodyRef}>
          <div className="popup-content">
            <div className="popup-header">
              <p className="large-text white-text center-text flex-grow">
                Customer Search
              </p>
              <a href="/dashboard">
                <svg className="icon-small icon-small-back">
                  <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
                </svg>
              </a>
            </div>
            <div className="popup-body popup-body-evenly">
              <div className="form-row">
                <label
                  htmlFor="search-type-all"
                  className="medium-text dark-blue-text"
                >
                  All
                </label>
                <div className="custom-radio">
                  <input
                    type="radio"
                    name="search-type"
                    id="search-type-all"
                    onChange={handleActiveRadio}
                    ref={allCustomersRef}
                  />
                  <div className="radio-button"></div>
                </div>

                <label
                  htmlFor="search-type-active"
                  className="medium-text dark-green-text"
                >
                  Active Only
                </label>
                <div className="custom-radio">
                  <input
                    type="radio"
                    name="search-type"
                    id="search-type-active"
                    onChange={handleActiveRadio}
                    ref={activeCycleRef}
                  />
                  <div className="radio-button"></div>
                </div>

                <label
                  htmlFor="search-type-hot"
                  className="medium-text dark-red-text"
                >
                  Hot
                </label>
                <div className="custom-radio">
                  <input
                    type="radio"
                    name="search-type"
                    id="search-type-hot"
                    onChange={handleActiveRadio}
                    ref={hotCycleRef}
                  />
                  <div className="radio-button"></div>
                </div>
              </div>
              <div className="form-row">
                <select
                  name="search-filter"
                  id="search-filter"
                  className="form-select form-select-left medium-text dark-blue-green-text flex-grow"
                  value={searchType}
                  onChange={handleSearchTypeChange}
                >
                  <option value="all-fields">All Fields</option>
                  <option value="first">First</option>
                  <option value="last">Last</option>
                  <option value="phone">Phone</option>
                  <option value="email">E-Mail</option>
                  <option value="zip">Zip</option>
                  <option value="stock">Stock</option>
                </select>
                <input
                  type="text"
                  className="form-field form-field-right medium-text dark-blue-green-text flex-grow"
                  placeholder="Enter Search Text..."
                  value={searchText}
                  onChange={handleSearchTextChange}
                  onKeyPress={(e) => {
                    e.persist();
                    if (e.key === "Enter") {
                      search(e);
                    }
                  }}
                />
              </div>

              <div className="flex-row-center-center large-text dark-blue-text">
                <p> Results: </p>
              </div>

              <form
                action=""
                className="popup-section popup-section-taller"
                ref={possibleDuplicateSection}
                onClick={setMergeButtonDisplay}
              >
                {searchResultsState &&
                  searchResultsState.length > 0 &&
                  searchResultsState.map((result, index) => {
                    return (
                      <div
                        className="module-checkbox-container"
                        id="parent"
                        key={index}
                      >
                        <div className="custom-checkbox">
                          <input
                            type="checkbox"
                            name="customer-search-results"
                            id={result._id}
                            className="checkbox-clickable"
                          />
                          <div className="checkbox margin-right-small small-text center-text"></div>
                        </div>
                        <div
                          className="module module-full-width"
                          onClick={handleResultClick}
                          id={JSON.stringify(result)}
                        >
                          <div className="module-main">
                            <div className="flex-row-center-center">
                              {result.face && result.face.length > 200 && (
                                <img
                                  className="avatar-smaller margin-right-small"
                                  src={`data:image/jpeg;base64,${result.face}`}
                                  alt="customer_face"
                                />
                              )}
                              {result.face && result.face <= 200 && (
                                <img
                                  className="avatar-smaller margin-right-small"
                                  src={`${result.face}`}
                                  alt="customer_face"
                                />
                              )}
                              <div className="flex-column-center-evenly">
                                <div className="module-data dark-blue-green-text">
                                  {result && result.firstName}{" "}
                                  {result && result.lastName}
                                </div>

                                <div className="module-data dark-blue-green-text">
                                  {result &&
                                    result.phoneNumber &&
                                    typeof result.phoneNumber === "string" &&
                                    parsePhoneNumber(
                                      result.phoneNumber
                                    ).formatNational()}
                                </div>

                                <div className="module-data dark-blue-green-text">
                                  {result &&
                                    result.emailAddress &&
                                    result.emailAddress}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="module-section module-section-normal">
                            <div className="white-text">Last Updated</div>

                            <div className="white-text">
                              {result &&
                                format(new Date(result.updatedAt), "P")}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </form>
              <div className="form-row">
                <button
                  className="button-elevated flex-grow medium-large-text margin-right-large"
                  onClick={search}
                >
                  Search
                </button>
                <button
                  className="button-normal button-normal-disabled flex-grow medium-large-text"
                  ref={mergeButton}
                  onClick={mergeCustomerHandler}
                >
                  Merge Selected
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CustomerSearch;
